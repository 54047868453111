// room to room animation
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry'
import { Pencil } from "./pencil";
import { degToRad } from "./utils";
import { Computer } from "./computer";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import {gsap} from "gsap/all";
import nodemailer from "nodemailer";

let isChecked = false

window.recaptcha_callback = function (){
    var response = grecaptcha.getResponse();

    if (response === 0) {
      isChecked = false;

      // console.log("false");
    } else {
      isChecked = true;
      // console.log("true");
    }
  }


const email = document.getElementById("emailInput")!;
const LName = document.getElementById("lnameInput")!;
const FName = document.getElementById("fNameInput")!;
const phone = document.getElementById("phoneInput")!;
const description = document.getElementById("messageInput")!;
const scrollingImg = document.getElementById("scrollingGifImg")!;
const btn = document.getElementById("contactUsSubmitButton")!;

btn.addEventListener("click", (e) => {
	let canWeSubmit = checkInputs();
    if (isChecked===true) {
        document.getElementById('captchaError')?.classList.remove('show')  
        //   console.log(canWeSubmit);
          // if we get canWeSubmit false than we don't call Api
          // otherwise we do with the object which was returned from checkInput();
          if (canWeSubmit) {
			contactUsSubmission(canWeSubmit).then((res) => {
				clearAllFeilds();
			});

          } else {
          
		  }
  } else if(isChecked===false){
	e.preventDefault();
    document.getElementById('captchaError')?.classList.add('show')  
    document.getElementById('captchaError')!.innerHTML = 'Kindly complete the reCAPTCHA for verification.'
	  return;
  }
});

const contactUsSubmission = async(data) => {
	const response = await fetch("https://g5wniq31j8.execute-api.ap-south-1.amazonaws.com/send-email", {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json'
		},
		mode: 'no-cors'
	});
	const status = await response.json();
	clearAllFeilds();
    
}

function clearAllFeilds() {
    
  (email as HTMLInputElement).value = "";
  (FName  as HTMLInputElement).value = "";
  (LName  as HTMLInputElement).value = "";
  (description  as HTMLInputElement).value = "";
  (phone  as HTMLInputElement).value = "";
  document.getElementById('LNameError')?.classList.remove('show')
  document.getElementById('FNameError')?.classList.remove('show')
  document.getElementById('emailError')?.classList.remove('show')
  document.getElementById('phoneError')?.classList.remove('show')
  document.getElementById('messageError')?.classList.remove('show')
}
let isValid=false, isLNameValid=false,isFNameValid=false,isPhoneValid=false, isEmailValid=false, isMessageValid=false
function checkInputs() {
    isValid=false
     isLNameValid=false
      isEmailValid=false
     isMessageValid=false
  const userEmail = (email as HTMLInputElement)?.value.trim();
  const lastName = (LName  as HTMLInputElement)?.value.trim();
  const firstName = (FName  as HTMLInputElement)?.value.trim();
  const phoneNumber = (phone  as HTMLInputElement)?.value.trim();
  const userdescription = (description  as HTMLInputElement)?.value.trim();

  if (userEmail === "") {
    document.getElementById('emailError')?.classList.add('show')
 document.getElementById('emailError')!.innerHTML = 'enter valid Email'
}
  if (lastName === "") {
    document.getElementById('LNameError')?.classList.add('show')
    document.getElementById('LNameError')!.innerHTML = 'enter valid Last Name'
}
  if (firstName === "") {
    document.getElementById('FNameError')?.classList.add('show')
    document.getElementById('FNameError')!.innerHTML = 'enter valid First Name'
}
  if (phoneNumber === "") {
    document.getElementById('phoneError')?.classList.add('show')
    document.getElementById('phoneError')!.innerHTML = 'enter valid Number'
}
 
  if (userdescription === "") {
    document.getElementById('messageError')?.classList.add('show')
    document.getElementById('messageError')!.innerHTML = 'enter valid Message'
}
if (
    (userEmail != "" ||
    lastName != "" ||
    firstName != "" ||
    phoneNumber != "" ||
    userdescription != "")
  ) {
    if(lastName!=""){ 
        document.getElementById('LNameError')?.classList.remove('show')
        isLNameValid=true
    }
    if(firstName!=""){ 
        document.getElementById('FNameError')?.classList.remove('show')
        isFNameValid=true
    }
    if(phoneNumber!=""){
        let numberFormat = /^\+(?:[0-9] ?){6,14}[0-9]$/; 
        if(phoneNumber.match(numberFormat)){
        document.getElementById('phoneError')?.classList.remove('show')
        isPhoneValid=true
    }else{
        document.getElementById('phoneError')?.classList.add('show')
        document.getElementById('phoneError')!.innerHTML = 'enter valid Number'
    }
    }
    if(userdescription!=""){
        document.getElementById('messageError')?.classList.remove('show')
        isMessageValid=true
    }
    if(userEmail!=""){
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(userEmail.match(mailformat)){
            document.getElementById('emailError')?.classList.remove('show')
        isEmailValid=true
        }else{
            document.getElementById('emailError')?.classList.add('show')
            document.getElementById('emailError')!.innerHTML = 'enter valid email format'
        }
    }
  }
  if(isEmailValid===true &&  isFNameValid===true && isPhoneValid===true &&isLNameValid===true && isMessageValid===true){
    isValid=true
}
  if (
    userEmail != "" &&
    lastName != "" &&
    firstName != "" &&
    phoneNumber != "" &&
    userdescription != "" && isValid===true && isChecked===true
  ) {
    document.getElementById('LNameError')?.classList.remove('show')
    document.getElementById('FNameError')?.classList.remove('show')
    document.getElementById('phoneError')?.classList.remove('show')
    document.getElementById('emailError')?.classList.remove('show')
    document.getElementById('messageError')?.classList.remove('show')
    setSuccessFor()
    return {
        firstName: firstName,
        lastName: lastName,
        phone: lastName,
        email: userEmail,
      description: userdescription,
    };
  } else {
    return false;
  }
}


function setSuccessFor() {
    let toastBox = document.getElementById("toastBox");
let toast = document.createElement("div");
toast.classList.add("toast");
toast.classList.add("success");
toast.innerHTML = 'Recieved successfully!';
toastBox?.appendChild(toast);
isChecked=true
// setTimeout(() => {
//  toast.remove();
// }, 2000);
}
// loadingScreen
let percentageDiv = document.getElementById("percentageDiv")!;
let progressBar = document.getElementById("progressBar")!;
let loadingText = document.getElementById("text")!;

document.addEventListener("DOMContentLoaded", function () {
    const mainElement = document.querySelector(".contactUsForm")!;
    mainElement.classList.add("show");
  });

// canvas
const canvas = document.querySelector('.webgl')

// scene
const scene = new THREE.Scene()

// sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

// camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 2500)
camera.enableDamping = true
camera.position.set(-91.84329604653897, 68.16199239699688, 163.31151995526898)
camera.rotation.y = -0.46
scene.add(camera)
const keys = {
    W: false
};
let scrollCount = 0

// // controls 
// const controls = new OrbitControls(camera, canvas)

// renderer
const renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true })
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setClearColor('#87CEEB')

// ambientLight
const ambientLight = new THREE.AmbientLight("#ffffff", 0.8);
// const directionalLight = new THREE.DirectionalLight('#fdfbd3',1);
const directionalLight = new THREE.DirectionalLight('#FDB813',0.25);
directionalLight.position.set(50, 60, 250)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.width = 512;
directionalLight.shadow.mapSize.height = 512;
directionalLight.shadow.camera.near = 1;
directionalLight.shadow.camera.far = 2500;
const groundWidth = 500;
directionalLight.shadow.camera.left = -groundWidth / 2;
directionalLight.shadow.camera.right = groundWidth /2;
directionalLight.shadow.camera.top = groundWidth / 2;
directionalLight.shadow.camera.bottom = -groundWidth / 2;
scene.add(directionalLight)

// loaders
const textureLoader = new THREE.TextureLoader();
const pc1ScreenTopTexture = textureLoader.load("/textures/pcScreens/pc1ScreenTop.jpg")
const pc1ScreenBottomTexture = textureLoader.load("/textures/pcScreens/pc1Bottom.jpg")
const pc2ScreenTopTexture = textureLoader.load("/textures/pcScreens/pc2Top.png")
const pc2ScreenBottomTexture = textureLoader.load("/textures/pcScreens/pc2Bottom.png")
const carParkingTexture = textureLoader.load("/textures/parkingTexture.jpg")
carParkingTexture.wrapS = THREE.RepeatWrapping;
carParkingTexture.wrapT = THREE.RepeatWrapping;
carParkingTexture.repeat.set(2, 2);
const wallDiffuseTexture = textureLoader.load("/textures/wall/synthetic_wood_diff_4k_LowSize.png")
const buildingwallDiffuseTexture = textureLoader.load("/textures/wall/floor_tiles_04_diff_4k_LowSize.png")
const roomwallDiffuseTexture = textureLoader.load("/textures/wall/sandstoneBlack_08_diff_4k_LowSize.jpg")
roomwallDiffuseTexture.wrapS = THREE.RepeatWrapping;
roomwallDiffuseTexture.wrapT = THREE.RepeatWrapping;
roomwallDiffuseTexture.repeat.set(12, 6);

const clocker = new THREE.Clock()
clocker.start()
const loadingManager = new THREE.LoadingManager(
    // loaded
    (itemUrl) => {
        document.querySelector('body')?.classList.remove('loading');
        let loadingScreen = document.querySelector(".loadingScreen")!
        if (loadingScreen) {
          
            loadingScreen.style.display = 'none';
            document.getElementById('body')!.style.overflowY = 'auto'
        }
    },
    // progress
    (itemUrl, itemLoaded, itemTotal) => {
        let width = ((itemLoaded / 78) * 100).toFixed(0) + "%";
        progressBar.style.width = width;
        percentageDiv.textContent = width;
    }
)
const gltfLoader = new GLTFLoader(loadingManager)
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/')
dracoLoader.setDecoderConfig({ type: 'js' })
gltfLoader.setDRACOLoader(dracoLoader)
const shelfGroup = new THREE.Group()
const vehiclesGroup = new THREE.Group()
scene.add(vehiclesGroup)
const parkingMaterial = new THREE.MeshLambertMaterial({ map: carParkingTexture })
const parkingMaterial2 = new THREE.MeshLambertMaterial({ color: '#dcd9d5' })
const parkingBase = new THREE.Mesh(new THREE.CircleGeometry(40, 32), parkingMaterial)
parkingBase.position.set(15, 1.25, 15)
const parkingTrack = new THREE.Mesh(new THREE.BoxGeometry(64.2, 1.85, 20, 32), parkingMaterial2)
parkingBase.rotateX(-Math.PI * 0.5)
parkingBase.receiveShadow = true
parkingTrack.receiveShadow = true
parkingTrack.position.set(-11, 0.2, 44.5)
scene.add(parkingBase, parkingTrack)

// room
const room = new THREE.Group()
room.add(ambientLight);
const room2 = new THREE.Group()
const connectingRoomsGroup = new THREE.Group()
const receptionGroup = new THREE.Group()


let yatch
gltfLoader.load('assets/yatch.gltf', function (gltf: any) {
    yatch = gltf.scene
    yatch.position.set(-500, -2, 1000)
    yatch.scale.set(3, 3, 3)
    yatch.rotateY(Math.PI * 0.25)
    vehiclesGroup.add(yatch)

})
let sportsCar, roadsterCar, policeCar, ambulance, mustang, limousine, bus, suv, fountain, Truck
gltfLoader.load('assets/vehicles/sportscar.gltf', function (gltf: any) {
    sportsCar = gltf.scene
    sportsCar.position.set(-10, 1.5, 10)
    sportsCar.scale.set(3, 3, 3)
    sportsCar.rotateY(Math.PI * 0.5)
    sportsCar.castShadow = true
    vehiclesGroup.add(sportsCar)

})
gltfLoader.load('assets/vehicles/suv.gltf', function (gltf: any) {
    suv = gltf.scene
    suv.position.set(-80, 0, 250)
    suv.scale.set(3.5, 3.5, 3.5)
    suv.castShadow = true
    suv.rotateY(Math.PI * 0.5)
    vehiclesGroup.add(suv)

})
gltfLoader.load('assets/vehicles/roadster.gltf', function (gltf: any) {
    roadsterCar = gltf.scene
    roadsterCar.position.set(-10, 1.5, 20)
    roadsterCar.scale.set(3, 3, 3)
    roadsterCar.rotateY(Math.PI * 0.5)
    roadsterCar.castShadow = true
    vehiclesGroup.add(roadsterCar)
})
gltfLoader.load('assets/vehicles/mustang.gltf', function (gltf: any) {
    mustang = gltf.scene
    mustang.position.set(-70, 0, 20)
    // mustang.rotateY(-Math.PI*0.5)
    mustang.scale.set(3, 3, 3)
    mustang.castShadow = true
    vehiclesGroup.add(mustang)
})
gltfLoader.load('assets/vehicles/limousine.gltf', function (gltf: any) {
    limousine = gltf.scene
    limousine.position.set(-110, 0,-100)
    limousine.rotateY(Math.PI)
    limousine.scale.set(3, 3, 6)
    limousine.castShadow = true
    vehiclesGroup.add(limousine)
})
gltfLoader.load('assets/vehicles/policemuscle.gltf', function (gltf: any) {
    policeCar = gltf.scene
    policeCar.position.set(-25, 0, -180)
    policeCar.rotateY(-Math.PI * 0.5)
    policeCar.scale.set(3, 3, 3)
    policeCar.castShadow = true
    vehiclesGroup.add(policeCar)
})
gltfLoader.load('assets/vehicles/ambulance.gltf', function (gltf: any) {
    ambulance = gltf.scene
    ambulance.position.set(-70, 0, -280)
    ambulance.scale.set(4, 4, 4)
    ambulance.castShadow = true
    vehiclesGroup.add(ambulance)
})
gltfLoader.load('assets/vehicles/bus.gltf', function (gltf: any) {
    bus = gltf.scene
    bus.position.set(130, 0, 250)
    bus.scale.set(4, 4, 4)
    bus.rotateY(Math.PI * 0.5)
    bus.castShadow = true
    vehiclesGroup.add(bus)
})
gltfLoader.load('assets/fountain.gltf', function (gltf: any) {
    fountain = gltf.scene
    fountain.position.set(160, 17, -350)
    fountain.scale.set(2, 2, 2)
    fountain.castShadow = true
    vehiclesGroup.add(fountain)
})

let human, human2, human3, chair1, chair2, chair3, chair4, plantFlowerPot, plantReception
gltfLoader.load('assets/flowerPot.gltf', function (gltf: any) {
    plantFlowerPot = gltf.scene
    plantFlowerPot.position.set(-0.7, 3.55, -0.8)
    plantFlowerPot.scale.set(1.2, 1.2, 1.2)
    shelfGroup.add(plantFlowerPot)
})
gltfLoader.load('assets/smallPlants.gltf', function (gltf: any) {
    plantReception = gltf.scene
    plantReception.position.set(4.25, -0.45, -1.3)
    plantReception.rotateY(Math.PI * 0.5)
    plantReception.scale.set(3, 3, 3)
    receptionGroup.add(plantReception)
})
gltfLoader.load('assets/humanBlue.gltf', function (gltf: any) {
    human = gltf.scene
    human.position.set(2.25, 0.3, 2.25)
    human.rotateY(Math.PI * 0.25)
    room.add(human)
})
gltfLoader.load('assets/human_with_animations.gltf', function (gltf: any) {
    human3 = gltf.scene
    human3.position.set(-5, 0.37, 2)
    human3.rotateY(Math.PI * 0.4)
    room.add(human3)
})

// chairs
gltfLoader.load('assets/chair.gltf', function (gltf: any) {
    chair1 = gltf.scene
    chair1.scale.set(2, 2, 2)
    chair1.rotateY(Math.PI)
    chair1.position.set(1.75, 0, 2)
    room.add(chair1)
})
gltfLoader.load('assets/chair.gltf', function (gltf: any) {
    chair2 = gltf.scene
    chair2.scale.set(2, 2, 2)
    chair2.rotateY(Math.PI)
    chair2.castShadow = true
    chair2.position.set(-1.5, 0, 2)
    room.add(chair2)
})
gltfLoader.load('assets/chair.gltf', function (gltf: any) {
    chair3 = gltf.scene
    chair3.scale.set(2, 2, 2)
    chair3.rotateY(Math.PI)
    chair3.position.set(-5, 0, 2)
    room.add(chair3)

})
let tv

gltfLoader.load('assets/tv.gltf', function (gltf: any) {
    tv = gltf.scene
    tv.scale.set(0.5, 0.4, 0.5)
    tv.position.set(0, 2, -0.5)
})
let room2Bookshelf, corridorFurniture
gltfLoader.load('assets/bookshelf.gltf', function (gltf: any) {
    room2Bookshelf = gltf.scene
    room2Bookshelf.position.set(-8, -5, 0.5)
    room2Bookshelf.scale.set(5, 5, 5)
    room2Bookshelf.rotateY(Math.PI * 0.5)
    room2.add(room2Bookshelf)
})
gltfLoader.load('assets/classicTable_painting.gltf', function (gltf: any) {
    corridorFurniture = gltf.scene
    corridorFurniture.rotateY(Math.PI * 0.5)
    corridorFurniture.position.set(-8.5, -5, -13.5)
    corridorFurniture.castShadow = true
    corridorFurniture.scale.set(3, 3, 3)
    room2.add(corridorFurniture)
})
let poolTable, poolTableAccessories
gltfLoader.load('assets/poolTable.gltf', function (gltf: any) {
    poolTable = gltf.scene
    poolTable.position.set(11, -5, 16.5)
    poolTable.scale.set(4, 4, 4)
    room2.add(poolTable)
})
gltfLoader.load('assets/poolTableAccessories.gltf', function (gltf: any) {
    poolTableAccessories = gltf.scene
    poolTableAccessories.rotateY(Math.PI * 0.5)
    poolTableAccessories.position.set(11, -6, 15)
    poolTableAccessories.scale.set(5, 4, 5)
    room2.add(poolTableAccessories)
})

// building
const mainbuildingGroup = new THREE.Group()
const buildingGeometry = new THREE.PlaneGeometry(51, 80)
const buildingMaterial = new THREE.MeshStandardMaterial({ color: '#ffffff', side: THREE.BackSide, map: buildingwallDiffuseTexture,roughness:0.4,metalness:0.4 })
const buildingGlassMaterial = new THREE.MeshPhysicalMaterial({
    color: '#A8CCD7',
    roughness: 0.6,
    metalness: 0,
    transmission: 1,
    // clearcoat : 1,
    side: THREE.BackSide
})
const buildingwallRight = new THREE.Mesh(buildingGeometry, buildingMaterial)
buildingwallRight.receiveShadow= true
buildingwallRight.position.set(-14.5, 0, -2)
const buildingFrontGeometry = new THREE.PlaneGeometry(31.55, 80)
const buildingFront = new THREE.Mesh(buildingFrontGeometry, buildingGlassMaterial)
buildingFront.rotateY(-Math.PI * 0.5)
buildingFront.position.set(11, 0, (buildingwallRight.geometry.parameters.width / 1.55))
const buildingFrontSectionGeometry = new THREE.PlaneGeometry(20.5, 30)
const buildingFrontSection2Geometry = new THREE.PlaneGeometry(20.5, 38.75)
const buildingFrontSection1 = new THREE.Mesh(buildingFrontSectionGeometry, buildingGlassMaterial)
buildingFrontSection1.rotateY(-Math.PI * 0.5)
buildingFrontSection1.position.set(11, 25, 7.8)
const buildingFrontSection2 = new THREE.Mesh(buildingFrontSection2Geometry, buildingGlassMaterial)
buildingFrontSection2.rotateY(-Math.PI * 0.5)
buildingFrontSection2.position.set(11, -19.5, 7.8)
const buildingWindowGlassGeometry = new THREE.BoxGeometry(0.01, 10, 19, 32)
const buildingWindowGlassMaterial = new THREE.MeshPhysicalMaterial({
    color: '#A8CCD7',
    opacity: 0.6,
    transparent: true,
    roughness: 0.1,
    metalness: 0.2,
    transmission: 1,
    clearcoat: 1,
})
const buildingWallGlass = new THREE.Mesh(buildingWindowGlassGeometry, buildingWindowGlassMaterial)
buildingWallGlass.position.set(11, 5, 7.7)
const buildingGridsGroup = new THREE.Group()
const verticalGridsGeometry = new THREE.PlaneGeometry(1, 80)
const horizontalGridsGeometry = new THREE.PlaneGeometry(50, 1)
const gridMaterial = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, color: '#8d8d8d' })
const railingGeometry = new THREE.BoxGeometry(50, 1, 1, 32)
const railingVerticalGeometry = new THREE.BoxGeometry(80, 1, 1, 32)
const railingTop = new THREE.Mesh(railingGeometry, gridMaterial)
railingTop.position.set(11.5, 39.5, 22.5)
railingTop.rotateY(Math.PI * 0.5)
const railingBottom = new THREE.Mesh(railingGeometry, gridMaterial)
railingBottom.position.set(11.5, -39.5, 22.5)
railingBottom.rotateY(Math.PI * 0.5)
const railingRight = new THREE.Mesh(railingVerticalGeometry, gridMaterial)
railingRight.position.set(11.5, 0, -2)
railingRight.rotateZ(Math.PI * 0.5)
const railingLeft = new THREE.Mesh(railingVerticalGeometry, gridMaterial)
railingLeft.position.set(11.5, 0, 48)
railingLeft.rotateZ(Math.PI * 0.5)
buildingGridsGroup.add(railingTop, railingBottom, railingRight, railingLeft)
let gridZ = -4
let gridY = -40
for (let i = 0; i <9; i++) {
    gridZ += (buildingFront.geometry.parameters.width / 6)
    const verticalGrids = new THREE.Mesh(verticalGridsGeometry, gridMaterial)
    verticalGrids.position.set(11.5, 0, gridZ)
    buildingGridsGroup.add(verticalGrids)
    mainbuildingGroup.add(buildingGridsGroup)
}
for (let i = 0; i <9; i++) {
    gridY += (buildingFront.geometry.parameters.height / 8)
    const horizontalGrids = new THREE.Mesh(horizontalGridsGeometry, gridMaterial)
    horizontalGrids.rotateY(Math.PI * 0.5)
    horizontalGrids.rotateX(Math.PI * 0.5)
    horizontalGrids.position.set(11.5, -gridY, 22.5)
    buildingGridsGroup.add(horizontalGrids)
    mainbuildingGroup.add(buildingGridsGroup)
}
const buildingExternalFloorGeometry = new THREE.BoxGeometry(50, 1, 60, 32)
const buildingExternalFloor2Geometry = new THREE.BoxGeometry(60, 1, 60, 32)
const externalFloorMaterial = new THREE.MeshBasicMaterial({ color: '#d3c8bd' })
const buildingExternalFloor = new THREE.Mesh(buildingExternalFloorGeometry, externalFloorMaterial)
buildingExternalFloor.position.set(-20, 30, 20)
const buildingExternalFloor2 = new THREE.Mesh(buildingExternalFloor2Geometry, externalFloorMaterial)
buildingExternalFloor2.position.set(-20, 20, 20)
const building2Material = new THREE.MeshStandardMaterial({ color: '#6a6a6a', map: buildingwallDiffuseTexture})
building2Material.roughness = 0.4
building2Material.metalness = 0.5
const buildingEntranceTopGeometry = new THREE.BoxGeometry(35, 2.5, 35, 32)
const buildingEntranceSideGeometry = new THREE.BoxGeometry(20, 2.5, 35, 32)
const buildingEntranceGeometry = new THREE.BoxGeometry(30, 20, 55, 32)
const buildingEntrance = new THREE.Mesh(new THREE.BoxGeometry(30,20,30,32), new THREE.MeshPhysicalMaterial({
    color: '#A8CCD7',
    roughness: 0.4,
    metalness: 0.2,
    transmission: 1,
}))
buildingEntrance.position.set(-40, -30, -15.5)
const buildingEntranceTop = new THREE.Mesh(buildingEntranceTopGeometry, building2Material)
buildingEntranceTop.position.set(-39, -21, -15.5)
const buildingEntranceLeft = new THREE.Mesh(buildingEntranceSideGeometry, building2Material)
buildingEntranceLeft.position.set(-25, -31, -15.5)
buildingEntranceLeft.rotateZ(Math.PI * 0.5)
const buildingEntranceRight = new THREE.Mesh(buildingEntranceSideGeometry, building2Material)
buildingEntranceRight.position.set(-55, -31, -15.5)
buildingEntranceRight.rotateZ(Math.PI * 0.5)
const buildingPillarGeometry = new THREE.CylinderGeometry(1, 1, 55, 32)
const buildingPillar = new THREE.Mesh(buildingPillarGeometry, externalFloorMaterial)
buildingPillar.position.set(-42, 8, -5)
const building2Geometry = new THREE.BoxGeometry(50, 72, 50, 32)
const building2 = new THREE.Mesh(building2Geometry, building2Material)
building2.position.set(-70, -10, 22)
building2.castShadow = true
buildingFront.castShadow = true
buildingwallRight.castShadow = true
mainbuildingGroup.add(building2, buildingPillar,buildingEntrance, buildingEntranceTop, buildingEntranceLeft, buildingEntranceRight, buildingExternalFloor, buildingExternalFloor2, buildingFront, buildingWallGlass, buildingFrontSection1, buildingFrontSection2, buildingwallRight)
scene.add(mainbuildingGroup)
const surroundingGroup = new THREE.Group()
const basesGeometry = new THREE.BoxGeometry(120, 1.5, 100)
const basesPlainGeometry = new THREE.BoxGeometry(220, 1.35, 200, 4)
const baseGrassMaterial = new THREE.MeshBasicMaterial({ color: '#1d4702' })
const basePlainMaterial = new THREE.MeshBasicMaterial({ color: '#6f6f6d' })
const extraBuildingGroup = new THREE.Group()

// buildings
const road = new THREE.Mesh(new THREE.PlaneGeometry(4000, 2500), new THREE.MeshStandardMaterial({ color: '#2f2f2f' }))
road.receiveShadow = true
road.rotateX(-Math.PI * 0.5)
road.position.set(250, 0, -950)
const parkPond = new THREE.Mesh(new THREE.PlaneGeometry(100, 200), new THREE.MeshStandardMaterial({ color: '#1fa6bf' }))
parkPond.position.set(180, 5, -380)
parkPond.rotateX(-Math.PI * 0.5)
scene.add(parkPond)
const ocean = new THREE.Mesh(new THREE.PlaneGeometry(4000, 2500), new THREE.MeshBasicMaterial({ color: '#05557d' }))
ocean.rotateX(-Math.PI * 0.5)
ocean.position.set(250, -10, 1500)
surroundingGroup.add(road, ocean)
let shoreLineUpperRailingGeometry = new THREE.CylinderGeometry(1, 1, 2500, 64)
let shoreLineLowerRailingGeometry = new THREE.CylinderGeometry(0.5, 0.5, 2500, 64)
let shoreLineLowerPillarsGeometry = new THREE.BoxGeometry(2.5, 20, 2.5, 64)
let shorelineMaterial = new THREE.MeshBasicMaterial({ color: '#abb8c3' })
const cityShoreLineGroup = new THREE.Group()
const shoreLineUpperRailing = new THREE.Mesh(shoreLineUpperRailingGeometry, shorelineMaterial)
const shoreLineLowerRailing2 = new THREE.Mesh(shoreLineLowerRailingGeometry, shorelineMaterial)
shoreLineUpperRailing.position.set(0, 0, -100)
shoreLineLowerRailing2.position.set(0, -10, -100)
shoreLineUpperRailing.rotateX(Math.PI * 0.5)
shoreLineLowerRailing2.rotateX(Math.PI * 0.5)
let pillarDistance = 1200
for (let i = 0; i < 120; i++) {
    const shorelinePillar = new THREE.Mesh(shoreLineLowerPillarsGeometry, shorelineMaterial)
    shorelinePillar.position.z = pillarDistance
    shorelinePillar.position.y = -10
    pillarDistance -= 20
    cityShoreLineGroup.add(shorelinePillar)
}
cityShoreLineGroup.position.set(800, 20, 298)
cityShoreLineGroup.rotateY(-Math.PI * 0.5)
cityShoreLineGroup.add(shoreLineUpperRailing, shoreLineLowerRailing2)
scene.add(cityShoreLineGroup)
const buildingArrays: any[] = []
let extraBuildings: THREE.Mesh
let extraBuildingsBase: THREE.Mesh
scene.add(surroundingGroup)
function makeExtraBuildings(width: number, height: number, depth: number, posX: number, posZ: number) {
    extraBuildings = new THREE.Mesh(new THREE.BoxGeometry(width, height, depth, 32), new THREE.MeshStandardMaterial({ color: '#d8e7f3', roughness:0.2,metalness:0.4 }))
    extraBuildings.position.set(posX, (extraBuildings.geometry.parameters.height / 2) + 1.1, posZ)
    surroundingGroup.add(extraBuildings)
    return extraBuildings
}
let basecolor: string
function makeExtraBuildingBase(width: number, height: number, posX: number, posZ: number, grass: boolean) {
    if (grass === true) {
        basecolor = '#225403'
    } else {
        basecolor = '#7e8385'
    }
    extraBuildingsBase = new THREE.Mesh(new THREE.BoxGeometry(width, height, 2, 32), new THREE.MeshLambertMaterial({ color: basecolor }))
    extraBuildingsBase.position.set(posX, 0.1, posZ)
    extraBuildingsBase.receiveShadow = true
    extraBuildingsBase.rotateX(-Math.PI * 0.5)
    surroundingGroup.add(extraBuildingsBase)
    return extraBuildingsBase
}
let treeHead: THREE.Mesh
let treeHeadWireframe: THREE.Mesh
let treeTrunk: THREE.Mesh
let treeBranchMaterial = new THREE.MeshBasicMaterial({ color: '#3b3025'})
function makeTrees(height: number, posX: number, posZ: number) {
    const treeGroup = new THREE.Group()
    treeTrunk = new THREE.Mesh(new THREE.CylinderGeometry((height / 12), (height / 10), height, 32), treeBranchMaterial)
    const branchRadius = (treeTrunk.geometry.parameters.radiusTop / 3)
    treeTrunk.position.set(posX, (treeTrunk.geometry.parameters.height / 2) + 1.1, posZ)
    treeTrunk.castShadow = true
    treeHead = new THREE.Mesh(new THREE.SphereGeometry(height / 1.5, 6, 6), new THREE.MeshBasicMaterial({ color: '#245603'}))
    treeHeadWireframe = new THREE.Mesh(new THREE.SphereGeometry(height / 1.49, 6, 6), new THREE.MeshBasicMaterial({ color: '#2d6b04', wireframe: true }))
    treeHead.position.set(posX, (treeTrunk.geometry.parameters.height / 1.3) + (treeHead.geometry.parameters.radius / 2) + 8.1, posZ)
    treeHeadWireframe.position.set(posX, (treeTrunk.geometry.parameters.height / 1.3) + (treeHead.geometry.parameters.radius / 2) + 8.1, posZ)
    treeHead.castShadow = true
    treeGroup.add(treeHead, treeTrunk, treeHeadWireframe)
    surroundingGroup.add(treeGroup)
    return treeGroup
}
let lightBase: THREE.Mesh
let lightBaseDown: THREE.Mesh
let lightBaseConnector: THREE.Mesh
let lightHead: THREE.Mesh
let streetLightColor = "#d8e7f3"
function makeStreetLights(height: number, posX: number, posZ: number, rotateInfo: boolean) {
    const streetLightGroup = new THREE.Group()
    lightBaseDown = new THREE.Mesh(new THREE.BoxGeometry(height / 4, (height * 0.015), (height * 0.2), 32), new THREE.MeshLambertMaterial({ color: streetLightColor}))
    lightBaseDown.position.set(posX, (lightBaseDown.geometry.parameters.height / 2) + 1.1, posZ)
    lightBaseDown.castShadow = true
    lightBase = new THREE.Mesh(new THREE.BoxGeometry((height * 0.01), height, height * 0.1, 32), new THREE.MeshLambertMaterial({ color: streetLightColor}))
    lightBase.position.set(posX, (lightBase.geometry.parameters.height / 2) + 1.1, posZ)
    lightBase.castShadow = true
    lightBaseConnector = new THREE.Mesh(new THREE.BoxGeometry((height / 4), (height * 0.01), (height * 0.04), 32), new THREE.MeshLambertMaterial({ color: streetLightColor}))
    lightBaseConnector.position.set(posX - (lightBaseConnector.geometry.parameters.width / 2), (lightBase.geometry.parameters.height) + 0.7, posZ)
    lightBaseConnector.castShadow = true
    lightHead = new THREE.Mesh(new THREE.BoxGeometry(height / 4, (height * 0.015), (height * 0.2), 32), new THREE.MeshLambertMaterial({ color: streetLightColor}))
    lightHead.position.set(posX - (lightHead.geometry.parameters.width / 1.2), (lightBase.geometry.parameters.height) + (lightHead.geometry.parameters.height / 2) + 0.5, posZ)
    lightHead.castShadow = true
    lightHead.castShadow = true
    lightBase.castShadow = true
    lightBaseDown.castShadow = true
    lightBaseConnector.castShadow = true
    streetLightGroup.add(lightHead, lightBase, lightBaseDown, lightBaseConnector)
    if (rotateInfo) {
        lightHead.position.x = posX + (lightBaseConnector.geometry.parameters.width / 2) + (lightHead.geometry.parameters.width / 4)
        lightBaseConnector.position.x = posX + (lightBaseConnector.geometry.parameters.width / 2)
    }
    surroundingGroup.add(streetLightGroup)
    return streetLightGroup
}
let monumentBase1: THREE.Mesh
let monumentBase3: THREE.Mesh
let monumentPillars1: THREE.Mesh
let monumentPillars2: THREE.Mesh
let monumentPillars3: THREE.Mesh
let monumentPillars4: THREE.Mesh
let monumentHeadBase: THREE.Mesh
let monumentHeadBall: THREE.Mesh
let monumentTop: THREE.Mesh
let monumentColor = '#d8e7f3'
let monumentColor2 = '#725F4B'
function makeGardenMonuments() {
    const gardenMonumentGroup = new THREE.Group()
    const height = 12
    monumentBase1 = new THREE.Mesh(new THREE.CylinderGeometry(30, 30, 2.5, 32), new THREE.MeshStandardMaterial({ color: monumentColor, roughness: 0.4, metalness: 0.6 }))
    monumentBase1.position.set(200, 3.1, 200)
    monumentBase1.castShadow = true
    monumentBase3 = new THREE.Mesh(new THREE.CylinderGeometry(25, 25, 2.5, 32), new THREE.MeshStandardMaterial({ color: monumentColor2, roughness: 0.4, metalness: 0.6 }))
    monumentBase3.position.set(200, 5.75, 200)
    monumentBase3.castShadow = true
    monumentPillars1 = new THREE.Mesh(new THREE.CylinderGeometry(2, 2, 30, 32), new THREE.MeshStandardMaterial({ color: monumentColor2, roughness: 0.4, metalness: 0.6 }))
    monumentPillars1.position.set(185, height + 7.1, 212)
    monumentPillars1.castShadow = true
    monumentPillars2 = new THREE.Mesh(new THREE.CylinderGeometry(2, 2, 30, 32), new THREE.MeshStandardMaterial({ color: monumentColor2, roughness: 0.4, metalness: 0.6 }))
    monumentPillars2.position.set(185, (height) + 7.1, 185)
    monumentPillars2.castShadow = true
    monumentPillars2.castShadow = true
    monumentPillars3 = new THREE.Mesh(new THREE.CylinderGeometry(2, 2, 30, 32), new THREE.MeshStandardMaterial({ color: monumentColor2, roughness: 0.4, metalness: 0.6 }))
    monumentPillars3.castShadow = true
    monumentPillars3.position.set(215, height + 7.1, 185)
    monumentPillars3.castShadow = true
    monumentPillars4 = new THREE.Mesh(new THREE.CylinderGeometry(2, 2, 30, 32), new THREE.MeshStandardMaterial({ color: monumentColor2, roughness: 0.4, metalness: 0.6 }))
    monumentPillars4.castShadow = true
    monumentPillars4.position.set(215, height + 7.1, 215)
    monumentPillars4.castShadow = true
    monumentHeadBase = new THREE.Mesh(new THREE.CylinderGeometry(25, 25, 5, 32), new THREE.MeshStandardMaterial({ color: monumentColor, roughness: 0.4, metalness: 0.6 }))
    monumentHeadBase.position.set(200, (height / 2) * 4.75 + 1.1, 200)
    monumentHeadBase.castShadow = true
    monumentHeadBall = new THREE.Mesh(new THREE.SphereGeometry(25, 32, 16, 0, 6.28, 4.8, 1.8), new THREE.MeshStandardMaterial({ color: monumentColor, roughness: 0.5, metalness: 0.6, side: THREE.BackSide }))
    monumentHeadBall.position.set(200, (height / 2) * 4.5 + 1.1, 200)
    monumentHeadBall.castShadow = true
    monumentTop = new THREE.Mesh(new THREE.CylinderGeometry(30, 25, 5, 32), new THREE.MeshStandardMaterial({ color: monumentColor2, roughness: 0.5, metalness: 0.6 }))
    monumentTop.position.set(200, (height) * 2.475 + 1.1, 200)
    monumentTop.castShadow = true
    gardenMonumentGroup.add(monumentBase1, monumentBase3, monumentPillars1, monumentPillars2, monumentPillars3, monumentPillars4, monumentHeadBase, monumentHeadBall, monumentTop)
    surroundingGroup.add(gardenMonumentGroup)
    return gardenMonumentGroup
}

// buildings
const extraBuilding1 = makeExtraBuildings(100, 30, 50, 200, 0)
const extraBuilding2 = makeExtraBuildings(100, 50, 50, -200, 0)
const extraBuilding3 = makeExtraBuildings(100, 50, 50, -200, 100)
const extraBuilding4 = makeExtraBuildings(100, 50, 50, -200, -100)
const extraBuilding5 = makeExtraBuildings(100, 50, 50, 10, -300)
extraBuilding1.receiveShadow = true
extraBuilding2.receiveShadow = true
extraBuilding3.receiveShadow = true
extraBuilding4.receiveShadow = true
extraBuilding5.receiveShadow = true
const extraBuilding6 = makeExtraBuildings(200, 15, 200, -300, -650)
const extraBuilding6side = makeExtraBuildings(100, 15, 200, -280, -580)
const extraBuilding7 = makeExtraBuildings(250, 100, 200, 90, -650)
const extraBuilding8 = makeExtraBuildings(150, 50, 450, 390, -550)
const extraBuilding9 = makeExtraBuildings(150, 60, 300, 790, -425)
const extraBuilding10 = makeExtraBuildings(100, 80, 50, 515, -110)
const extraBuilding11 = makeExtraBuildings(50, 80, 270, 715, 40)
const extraBuilding12 = makeExtraBuildings(100, 100, 80, 515, 40)
const extraBuilding13 = makeExtraBuildings(200, 90, 50, 770, -700)
const extraBuilding16 = makeExtraBuildings(150, 110, 150, 1100, -1100)
const extraBuilding17 = makeExtraBuildings(220, 110, 150, 830, -1100)
const extraBuilding18 = makeExtraBuildings(220, 160, 150, 330, -1100)
const extraBuilding19 = makeExtraBuildings(130, 130, 150, 50, -1100)
const extraBuilding20 = makeExtraBuildings(230, 40, 150, -100, -1150)
const extraBuilding20side = makeExtraBuildings(120, 60, 150, -100, -1150)
extraBuilding20.rotateY(-Math.PI * 0.225)
extraBuilding20side.rotateY(-Math.PI * 0.225)
const extraBuilding21 = makeExtraBuildings(420, 20, 150, -410, -1410)
extraBuilding21.rotateY(-Math.PI * 0.225)
const extraBuilding23 = makeExtraBuildings(220, 40, 150, -830, -1790)
const extraBuilding23side = makeExtraBuildings(220, 60, 150, -690, -1920)
extraBuilding23.rotateY(-Math.PI * 0.265)
extraBuilding23side.rotateY(Math.PI * 0.265)
const extraBuilding26 = makeExtraBuildings(180, 70, 150, -1000, -1320)
extraBuilding26.rotateY(Math.PI * 0.25)
const extraBuilding27 = makeExtraBuildings(220, 150, 50, -1100, -2000)
extraBuilding27.rotateY(Math.PI * 0.25)
const extraBuilding29 = makeExtraBuildings(220, 40, 150, 1960, -470)
const extraBuilding44 = makeExtraBuildings(80, 250, 100, 1090, -510)
const extraBuilding45 = makeExtraBuildings(180, 235, 150, 990, -600)

// bases
const mainBuildingBase = makeExtraBuildingBase(350, 400, 132, 40, true)
mainBuildingBase.receiveShadow = true
const extraBase1 = makeExtraBuildingBase(350, 300, -310, 0, false)
// extraBase1.rotateZ(Math.PI*0.05)
const extraBase2 = makeExtraBuildingBase(550, 600, -410, -560, true)
const extraBase2side = makeExtraBuildingBase(810, 700, -667, -790, true)
extraBase2side.rotateZ(Math.PI * 0.27)
const extraBase3 = makeExtraBuildingBase(550, 600, 232, -565, true)
const extraBase4 = makeExtraBuildingBase(550, 600, 982, -565, false)
const extraBase5 = makeExtraBuildingBase(550, 400, 730, 20, true)
const extraBase6 = makeExtraBuildingBase(550, 900, -190, -1465, false)
extraBase6.rotateZ(Math.PI * 0.27)
const extraBase7 = makeExtraBuildingBase(550, 600, 232, -1265, false)
const extraBase8 = makeExtraBuildingBase(550, 600, 982, -1265, false)
const extraBase10 = makeExtraBuildingBase(550, 600, -1200, -1365, false)
extraBase10.rotateZ(Math.PI * 0.26)
const extraBase11 = makeExtraBuildingBase(550, 600, -825, -2035, false)
extraBase11.rotateZ(Math.PI * 0.26)
const extraBase12 = makeExtraBuildingBase(550, 600, 1700, -565, true)

// trees
const mainBuildingTree1 = makeTrees(15, -20, 90)
mainBuildingTree1.castShadow = true
const mainBuildingTree2 = makeTrees(25, 10, 80)
mainBuildingTree2.children[0].castShadow = false
mainBuildingTree2.children[1].castShadow = false
const mainBuildingTree3 = makeTrees(20, -20, -130)
const extraTrees = makeTrees(20, 470, -50)
const extraTrees2 = makeTrees(20, -400, -290)
const extraTrees3 = makeTrees(30, -200, -290)
const extraTrees4 = makeTrees(15, -170, -390)
const extraTrees5 = makeTrees(15, 175, -500)
const extraTrees6 = makeTrees(25, 125, -500)
const extraTrees7 = makeTrees(30, 225, -500)
const extraTrees8 = makeTrees(20, 275, -450)
const extraTrees9 = makeTrees(30, 180, -270)
const extraTrees10 = makeTrees(20, 275, -350)
const extraTrees11 = makeTrees(20, -530, -1060)
const extraTrees12 = makeTrees(40, 1730, -460)

// streetLight
const mainBuildingStreetLight1 = makeStreetLights(40, -35, 130, false)
const mainBuildingStreetLight2 = makeStreetLights(50, -35, -80, false)
const extraStreetLight1 = makeStreetLights(40, -145, -330, true)
const extraStreetLight2 = makeStreetLights(40, -145, -830, true)
const extraStreetLight3 = makeStreetLights(40, -145, -830, true)
const extraStreetLight4 = makeStreetLights(40, 280, -130, true)

// gardenMonument
const gardenMonument1 = makeGardenMonuments()
gardenMonument1.position.set(-100, 0, -100)

// table
const tableGroup = new THREE.Group()
const tableGroupRoom2 = new THREE.Group()
const mattressLayer1Geometry = new RoundedBoxGeometry(18, 15, 0.05, 7, 0.2)
const mattressLayer2Geometry = new RoundedBoxGeometry(14, 12, 0.05, 7, 0.2)
const mattressLayer3Geometry = new RoundedBoxGeometry(12, 8, 0.05, 7, 0.2)
const mattressMaterial = new THREE.MeshBasicMaterial({ color: '#8a9c9d' })
const mattressMaterial2 = new THREE.MeshBasicMaterial({ color: '#00293b' })
const mattressMaterial3 = new THREE.MeshBasicMaterial({ color: '#8a9c9d' })
const mattressLayer1 = new THREE.Mesh(mattressLayer1Geometry, mattressMaterial)
const mattressLayer2 = new THREE.Mesh(mattressLayer2Geometry, mattressMaterial2)
const mattressLayer3 = new THREE.Mesh(mattressLayer3Geometry, mattressMaterial3)
mattressLayer1.position.set(0, 0.025, 8.5)
mattressLayer2.position.set(0, 0.03, 8.5)
mattressLayer3.position.set(0, 0.035, 8.5)
mattressLayer1.rotateX(degToRad(90))
mattressLayer2.rotateX(degToRad(90))
mattressLayer3.rotateX(degToRad(90))
mattressLayer1.receiveShadow = true
mattressLayer2.receiveShadow = true
mattressLayer3.receiveShadow = true
room.add(mattressLayer1, mattressLayer2, mattressLayer3)

const tableTopGeometry = new RoundedBoxGeometry(12, 2, 0.2, 7, 0.2)
const tableToproom2Geometry = new RoundedBoxGeometry(10, 2, 0.2, 7, 0.2)
const tableTopMaterial = new THREE.MeshBasicMaterial({ color: '#f7f5f2' })
const tableTop = new THREE.Mesh(tableTopGeometry, tableTopMaterial)
const tableTopRoom2 = new THREE.Mesh(tableToproom2Geometry, tableTopMaterial)
tableTop.receiveShadow = true
tableTop.rotateY(degToRad(90))
tableTop.rotateX(degToRad(90))
tableTop.rotateZ(-degToRad(90))
tableTop.translateZ(-1.5)
tableTop.translateX(2)
tableTopRoom2.receiveShadow = true
tableTopRoom2.rotateY(degToRad(90))
tableTopRoom2.rotateX(degToRad(90))
tableTopRoom2.rotateZ(-degToRad(90))
tableTopRoom2.translateZ(-1.5)
tableTopRoom2.translateX(1)
const standGeometry = new THREE.CylinderGeometry(0.2, 0.1, 1.5, 32)
const standMaterial = new THREE.MeshBasicMaterial({ color: '#f8b300' })
const stand1 = new THREE.Mesh(standGeometry, standMaterial)
stand1.translateY(0.75)
stand1.translateX(3.5)
const stand2 = new THREE.Mesh(standGeometry, standMaterial)
stand2.translateY(0.75)
const stand3 = new THREE.Mesh(standGeometry, standMaterial)
stand3.translateY(0.75)
stand3.translateX(-3.5)
const stand4 = new THREE.Mesh(standGeometry, standMaterial)
stand4.translateY(0.75)
stand4.translateX(-7)
const stand3Room2 = new THREE.Mesh(standGeometry, standMaterial)
stand3Room2.translateY(0.75)
stand3Room2.translateX(-5)

const stand1Room2 = new THREE.Mesh(standGeometry, standMaterial)
stand1Room2.translateY(0.75)
stand1Room2.translateX(2.5)
const stand2Room2 = new THREE.Mesh(standGeometry, standMaterial)
stand2Room2.translateY(0.75)
stand2Room2.translateX(-1.5)

const pc1 = new Computer(false, true)
const pc2 = new Computer(false, false)
pc2.monitorMaterial.map = pc1ScreenTopTexture
const pc3 = new Computer(true, true)

let keyboard1, keyboard2, keyboard3, keyboard1room2, keyboard2room2 
gltfLoader.load('assets/keyboard&mouse.gltf', function (gltf: any) {
    keyboard1 = gltf.scene
    keyboard1.position.set(0.2, 1.38, 0.75)
    pc1.pcGrp.add(keyboard1)

})
gltfLoader.load('assets/keyboard&mouse.gltf', function (gltf: any) {
    keyboard2 = gltf.scene
    keyboard2.position.set(0.5, 1.38, 0.75)
    pc2.pcGrp.add(keyboard2)
})
gltfLoader.load('assets/keyboard&mouse.gltf', function (gltf: any) {
    keyboard3 = gltf.scene
    // keyboard3.rotateY(Math.PI*0.5)
    keyboard3.position.set(0.3, 1.38, 0.75)
    pc3.pcGrp.add(keyboard3)
})
const pc1Room2 = new Computer(false, true)
const pc2Room2 = new Computer(false, false)
gltfLoader.load('assets/keyboard&mouse.gltf', function (gltf: any) {
    keyboard1room2 = gltf.scene
    keyboard1room2.position.set(-0.05, 1.38, 0.75)
    pc1Room2.pcGrp.add(keyboard1room2)
})
gltfLoader.load('assets/keyboard&mouse.gltf', function (gltf: any) {
    keyboard2room2 = gltf.scene
    keyboard2room2.position.set(0, 1.38, 0.75)

    pc2Room2.pcGrp.add(keyboard2room2)

})
pc1.pcGrp.position.set(2, 0, 0)
pc2.pcGrp.position.set(-1.5, 0, 0)
pc1Room2.pcGrp.position.set(-3.5, 0, 0)
pc2Room2.pcGrp.position.set(0, 0, 0)
pc2Room2.monitorMaterial.map = pc2ScreenTopTexture

pc3.pcGrp.position.set(-5, 0, 0)
tableGroupRoom2.add(tableTopRoom2, stand1Room2,stand2Room2,stand3Room2, pc1Room2.pcGrp, pc2Room2.pcGrp)
scene.add(tableGroupRoom2)
tableGroupRoom2.position.set(-3, 41, -13)
tableGroupRoom2.rotateY(Math.PI * 0.5)
tableGroup.add(tableTop, stand1, stand2, stand3, stand4, pc1.pcGrp, pc2.pcGrp, pc3.pcGrp)

// penholder
const penholderGroup = new THREE.Group()
const holderGeometry = new THREE.CylinderGeometry(1, 1, 2, 16, 1, true)
const holderMaterial = new THREE.MeshBasicMaterial({ color: '#2b353f',side:THREE.DoubleSide })
const holder = new THREE.Mesh(holderGeometry, holderMaterial)
holder.castShadow = true
const holderTopGeometry = new THREE.RingGeometry(0.7, 1, 16)
const holderTopMaterial = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, color: '#cebbaa' })
const holderTop = new THREE.Mesh(holderTopGeometry, holderTopMaterial)
holderTop.position.set(0, 1, 0)
holderTop.rotateX(degToRad(90))
const pencil1 = new Pencil(2.7, 0.3, '#44929f');
const pencil2 = new Pencil(2.7, 0.3, '#6335ab');

pencil1.pencilGrp.position.set(-0.45, 1, 0)
pencil2.pencilGrp.position.set(0.35, 1, 0)
pencil1.pencilGrp.rotateX(-Math.PI * 0.03)
pencil1.pencilGrp.rotateZ(Math.PI * 0.03)
pencil2.pencilGrp.rotateX(-Math.PI * 0.1)
pencil2.pencilGrp.rotateZ(-Math.PI * 0.15)

penholderGroup.add(holder, holderTop, pencil1.pencilGrp, pencil2.pencilGrp)
penholderGroup.scale.set(0.15, 0.15, 0.15)
penholderGroup.position.set(0, 1.7, 0)
room.add(tableGroup, penholderGroup)

const planeGeometry = new THREE.PlaneGeometry(24, 19);
const planeMaterial = new THREE.MeshBasicMaterial({ color: '#F6F5F5' })
const ceilingMaterial = new THREE.MeshBasicMaterial({ color: '#8a9c9d', side: THREE.BackSide })

const floor = new THREE.Mesh(planeGeometry, planeMaterial)
floor.rotateX(-degToRad(90))
floor.translateY(-8)
floor.translateX(-1)
floor.receiveShadow = true
const ceiling = new THREE.Mesh(planeGeometry, ceilingMaterial)
ceiling.rotateX(-degToRad(90))
ceiling.translateY(-8)
ceiling.translateX(-1)
ceiling.translateZ(10)

const glassGeometry = new THREE.BoxGeometry(0.15, 4, 6, 32)
const glassMaterial = new THREE.MeshPhysicalMaterial({
    color: '#A8CCD7',
    opacity: 0.6,
    transparent: true,
    roughness: 0.1,
    metalness: 0.2,
    transmission: 1,
    clearcoat: 1,
})
const glass = new THREE.Mesh(glassGeometry, glassMaterial)
receptionGroup.position.set(floor.geometry.parameters.width / 5, (glass.geometry.parameters.height / 2), (floor.geometry.parameters.height) - (glass.geometry.parameters.depth / 1.3))
glass.castShadow = true
receptionGroup.add(glass)
tableGroup.add(receptionGroup)
const receptionTableTopGeometry = new RoundedBoxGeometry(3.5, 2, 2, 7, 0.2)
const receptionTableTopMaterial = new THREE.MeshBasicMaterial({ color: '#F6F5F5' })
const receptionTableTop = new THREE.Mesh(receptionTableTopGeometry, receptionTableTopMaterial)
receptionTableTop.rotateX(degToRad(90))
receptionTableTop.rotateZ(-degToRad(90))
receptionTableTop.position.set(3, -1, 0.5)
const receptionBaseGeometry = new RoundedBoxGeometry(3, 1, 1.3, 7, 0.2)
const receptionBaseMaterial = new THREE.MeshBasicMaterial({ color: '#56342A' })
const receptionBase = new THREE.Mesh(receptionBaseGeometry, receptionBaseMaterial)
receptionBase.position.set(3.65, -1.25, -1)
receptionBase.rotateX(degToRad(90))
receptionBase.rotateZ(-degToRad(90))
const pcReception = new Computer(false, false)
pcReception.pcGrp.position.set(3.25, -1.6, 1.45)
pcReception.pcGrp.rotateY(-Math.PI * 0.6)
receptionGroup.add(receptionTableTop, receptionBase, pcReception.pcGrp)
const wallGroup = new THREE.Group()
const wallGeometry = new THREE.PlaneGeometry(24, 10);
const wallLeftGeometry = new THREE.PlaneGeometry(20, 10);
const tableWallMaterial = new THREE.MeshBasicMaterial({ color: "#808080", map: wallDiffuseTexture})
const wall = new THREE.Mesh(wallGeometry, tableWallMaterial)
const wallLeftMaterial = new THREE.MeshStandardMaterial({ color: '#F6F5F5', side: THREE.DoubleSide })
const wallLeft = new THREE.Mesh(wallLeftGeometry, wallLeftMaterial)
wallLeft.receiveShadow = true
wall.position.set(-1, 5, -1.5)
wallLeft.position.set(-13, 5, 8)
wallLeft.rotateY(Math.PI * 0.5)
wall.receiveShadow = true;
wallGroup.add(wall)
shelfGroup.position.set(-13.6, 0.5, 1)
shelfGroup.scale.set(1.5, 1.5, 1.5)
shelfGroup.rotateY(-Math.PI * 0.5)
const shelfGeometry = new THREE.BoxGeometry(0.1, 1)
const shelfMaterial = new THREE.MeshBasicMaterial({ color: '#8a9c9d' })
const shelf = new THREE.Mesh(shelfGeometry, shelfMaterial)
shelfGroup.add(shelf)
tableGroup.add(shelfGroup)
shelf.position.set(-1, 3.5, -1)
shelf.rotateY(Math.PI / 2)
shelf.rotateZ(Math.PI / 2)
shelf.castShadow = true

// extraRooms

const connectingRoomMaterial = new THREE.MeshBasicMaterial({ color: '#233543' })
const connectingFloorGeometry = new THREE.PlaneGeometry(50, 45)
const connectingCeilingGeometry = new THREE.PlaneGeometry(23, 30)
const connectingFloor = new THREE.Mesh(connectingFloorGeometry, planeMaterial)
connectingFloor.rotateX(-degToRad(90))
connectingFloor.receiveShadow = true
connectingFloor.position.set(-18, 0, 25)
const connectingCeiling = new THREE.Mesh(connectingCeilingGeometry, ceilingMaterial)
connectingCeiling.rotateX(-degToRad(90))
connectingCeiling.position.set(-7.5, 11.99, 29)

const connectingRoomLeftGeometry = new THREE.BoxGeometry(7.5, 15, 27, 32)
const connectingRoomFrontGeometry = new THREE.BoxGeometry(25.5, 10, 5, 32)
const connectingRoom1 = new THREE.Mesh(connectingRoomLeftGeometry, connectingRoomMaterial)
connectingRoom1.position.set(7.145, 5, 30.75)
const connectingRoom3 = new THREE.Mesh(connectingRoomFrontGeometry, buildingWindowGlassMaterial)
connectingRoom3.position.set(-6.2, 5, 40)
connectingRoomsGroup.add(connectingFloor, connectingCeiling, connectingRoom1)

// companyNameText
let companyNameText, companyNameText2, roomText
const loader = new FontLoader();
loader.load('fonts/helvetiker_regular.typeface.json', function (font) {
    const roomlogoTextGeometry = new TextGeometry('we are fluxbyte', {
        font: font,
        size: 1,
        height: 0.005,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: -0.25,
        bevelSize: 0.025,
        bevelOffset: 0,
        bevelSegments: 0.5
    });

    const logoTextGeometry = new TextGeometry('fluxbyte', {
        font: font,
        size: 6,
        height: 0.005,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: -1,
        bevelSize: 0.25,
        bevelOffset: 0,
        bevelSegments: 0.5
    });
    const logoText2Geometry = new TextGeometry('Technologies', {
        font: font,
        size: 2,
        height: 0.005,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: -0.5,
        bevelSize: 0.05,
        bevelOffset: 0,
        bevelSegments: 0.5
    });
    const textMaterial = new THREE.MeshStandardMaterial({ color: '#16579d', roughness: 0.6, metalness: 0.5 })
     companyNameText = new THREE.Mesh(logoTextGeometry, textMaterial)
    companyNameText.position.set(0, -4, -2.7)
    companyNameText.rotateY(Math.PI)
     companyNameText2 = new THREE.Mesh(logoText2Geometry, textMaterial)
    companyNameText2.position.set(-12.5, -8, -2.7)
    companyNameText2.rotateY(Math.PI)
     roomText = new THREE.Mesh(roomlogoTextGeometry, textMaterial)
    roomText.position.set(-13, 5, 13)
    roomText.rotateY(Math.PI * 0.5)
    room.add(roomText)
    mainbuildingGroup.add(companyNameText, companyNameText2)

});

room.add(floor, ceiling, wall, wallLeft)

// waterBottle
const bottleGroup = new THREE.Group()
const bottleGeometry = new THREE.CylinderGeometry(0.25, 0.25, 1.25, 32, 1)
const bottleMaterial = new THREE.MeshBasicMaterial({ color: '#16579d' })
const bottleCapLayerMaterial = new THREE.MeshBasicMaterial({ color: '#fbb52e' })
const bottleBase = new THREE.Mesh(bottleGeometry, bottleMaterial)
const bottleHeadGeometry = new THREE.CapsuleGeometry(0.25, 0, 4, 32)
const bottleHead = new THREE.Mesh(bottleHeadGeometry, bottleMaterial)
bottleHead.translateY(bottleBase.geometry.parameters.height / 2)
const bottleCapGeometry = new THREE.CylinderGeometry(0.15, 0.15, 0.8, 32, 1)
const bottleCapLayerGeometry = new THREE.CylinderGeometry(0.17, 0.17, 0.15, 32, 1)
const bottleCap = new THREE.Mesh(bottleCapGeometry, bottleMaterial)
const bottleCapLayer = new THREE.Mesh(bottleCapLayerGeometry, bottleCapLayerMaterial)
bottleCap.translateY((bottleBase.geometry.parameters.height / 2) + (bottleHead.geometry.parameters.height))
bottleCapLayer.translateY((bottleBase.geometry.parameters.height / 2) + (bottleHead.geometry.parameters.height) + 0.165)
bottleGroup.add(bottleBase, bottleHead, bottleCap, bottleCapLayer)
bottleGroup.position.set(-3.5, 2, 0)
bottleGroup.scale.set(0.6, 0.6, 0.6)
tableGroup.add(bottleGroup)

// books
const book2Group = new THREE.Group()
const book1Group = new THREE.Group()
const book1BaseGeometry = new THREE.CylinderGeometry(0.1, 0.1, 0.5, 32, 1, true, -1, 1.9)
const book2BaseGeometry = new THREE.CylinderGeometry(0.1, 0.1, 0.4, 32, 1, true, -1, 1.9)
const book1Material = new THREE.MeshBasicMaterial({ color: "#16579d" })
const book2Material = new THREE.MeshBasicMaterial({ color: "#2b353f" })
const bookPagesMaterial = new THREE.MeshBasicMaterial({ color: "#ffffff" })
book1Group.position.set(-1.45, 3.8, -0.8)
book2Group.position.set(-1.25, 3.75, -0.8)
const book1CoverGeometry = new THREE.BoxGeometry(0.35, 0.5, 0.01, 32)
const book2CoverGeometry = new THREE.BoxGeometry(0.35, 0.4, 0.01, 32)
const book1CoverBack = new THREE.Mesh(book1CoverGeometry, book1Material)
const book1CoverFront = new THREE.Mesh(book1CoverGeometry, book1Material)
const book2CoverBack = new THREE.Mesh(book2CoverGeometry, book2Material)
const book2CoverFront = new THREE.Mesh(book2CoverGeometry, book2Material)
const book1Base = new THREE.Mesh(book1BaseGeometry, book1Material)
const book2Base = new THREE.Mesh(book2BaseGeometry, book2Material)
book1Base.position.set(0.08, 0, (book1CoverBack.geometry.parameters.width / 3.3))
book2Base.position.set(0.08, 0, (book1CoverBack.geometry.parameters.width / 3.3))
book1CoverFront.position.set(book1Base.geometry.parameters.radiusTop * 1.55, 0, 0)
book2CoverFront.position.set(book1Base.geometry.parameters.radiusTop * 1.55, 0, 0)
book1CoverBack.rotateY(Math.PI * 0.5)
book1CoverFront.rotateY(Math.PI * 0.5)
book2CoverFront.rotateY(Math.PI * 0.5)
book2CoverBack.rotateY(Math.PI * 0.5)
const book1pagesGeometry = new RoundedBoxGeometry(0.15, 0.5, 0.4, 7, 0.2)
const book2pagesGeometry = new RoundedBoxGeometry(0.15, 0.4, 0.4, 7, 0.15)
const book1Pages = new THREE.Mesh(book1pagesGeometry, bookPagesMaterial)
const book2Pages = new THREE.Mesh(book2pagesGeometry, bookPagesMaterial)
book1Pages.position.set(0.075, 0, 0)
book2Pages.position.set(0.08, 0, 0)
book1Group.add(book1Base, book1CoverBack, book1CoverFront, book1Pages)
book2Group.add(book2Base, book2CoverBack, book2CoverFront, book2Pages)
shelfGroup.add(book1Group, book2Group)
// room2
const connectingWall = new THREE.Mesh(new THREE.PlaneGeometry(3, 15), new THREE.MeshBasicMaterial({ color: '#233543' }))
connectingWall.position.set(15.5, 0, -7)
room2.add(connectingWall)
const room2Material = new THREE.MeshBasicMaterial({ color: '#fbad18' })
const room2Material2 = new THREE.MeshBasicMaterial({ color: '#555c62', map: roomwallDiffuseTexture })
const room2MaterialCeiling = new THREE.MeshBasicMaterial({ color: '#aca1a0' })
const mattressMaterialroom2 = new THREE.MeshBasicMaterial({ color: '#f1eae4' })
const room2Geometry = new THREE.PlaneGeometry(48, 15)
const room2Geometry4 = new THREE.PlaneGeometry(28, 15)
const room2Geometry2 = new THREE.PlaneGeometry(38, 15)
const room2Geometry3 = new THREE.PlaneGeometry(12, 15)
const room2GeometryCeiling = new THREE.PlaneGeometry(25, 38)
const room2WallFront = new THREE.Mesh(room2Geometry2, room2Material2)
const room2WallLeft = new THREE.Mesh(room2Geometry, buildingWindowGlassMaterial)
const room2WallRight = new THREE.Mesh(room2Geometry4, buildingWindowGlassMaterial)
room2WallRight.receiveShadow = true
const room2WallCeiling = new THREE.Mesh(room2GeometryCeiling, mattressMaterialroom2)
const room2Wallside = new THREE.Mesh(room2Geometry3, room2Material2)
room2WallLeft.position.set(10, 0, 19)
room2WallLeft.rotateY(Math.PI)
room2WallRight.position.set(0, 0, -19)
room2WallFront.position.set(-10, 0, 0)
room2WallFront.rotateY(Math.PI * 0.5)
room2Wallside.position.set(14, 0, -13)
room2Wallside.rotateY(-Math.PI * 0.5)
room2WallCeiling.position.set(2, 7, 0)
room2WallCeiling.rotateX(Math.PI * 0.5)
room2.position.set(-30, 5, 25)
const mattressLayer1Geometryroom2 = new RoundedBoxGeometry(18, 30, 0.05, 7, 0.2)
const mattressRoom2 = new THREE.Mesh(mattressLayer1Geometryroom2, mattressMaterialroom2)
mattressRoom2.rotateX(Math.PI * 0.5)
mattressRoom2.position.set(0, -5, 0)
room2.add(room2WallLeft, room2WallFront, room2WallRight, room2Wallside, room2WallCeiling, mattressRoom2)
mainbuildingGroup.add(room2, connectingRoomsGroup, room)
mainbuildingGroup.rotateY(Math.PI)
mainbuildingGroup.position.set(-20, (buildingwallRight.geometry.parameters.height / 2) + 1.1, 0)

let prevScrollY = window.scrollY
let radius = 5
let positionArray = [[
    -91.84329604653897
    ,
    68.16199239699688
    ,
    163.31151995526898],[ 
        -95.54282268980486
        , 
        67.8
        ,
        150.67760974881834],[ 
            -98.37
            , 
           61.75
            , 
            67.75],
[
    -81.52
    ,
   55.7
    ,
    0.8],[ 
        -64.67
        , 
      49.65
        , 
        -2.55],[-30.9,
            46.5,
        -6.9],[
            -25.18840235094497
        ,
            45
         ,
            -6.587369242403111],

[
    -18.78327005720144,
    43.68067689093893,
    -2.5495957963973552], [
  
-18.52493496750867
,
43.61139798507676
,
-0.7527263334913478],
[
    -18.78327005720144,
    43.68067689093893,
    -2.5495957963973552],[ 
        -18.31866491310213
        , 
        45.92613720262452
,
        -6.873011689088539], [-18.689143917296505
    ,
    45.25804997524381
    ,
    -24.592000019728275],[ 
-17.6891,
45.258,
-26.592], [
    -6.6891,
    45.258 , 
    -36.592], [3.0415892539322065
    ,
    47.499428743853585
    ,
    -32.79641313372098],[ 
        3.596184912276474
      ,
        46.104669201609425
     ,
        -22.174184776863285],[ 
        3.060599734074266
        , 
        45.48428255686839
        ,
        -18.12254467136733], [2.405144124399108
    ,
    44.27846149080286
    ,
    -13.381588184446903], [
    -2.25
    ,
    43.4951740152804607
    ,
    -13.026109193665707], [2.405144124399108
    ,
    44.27846149080286
    ,
    -13.381588184446903], [
    -15.169851452988667
    ,
    37.89667063275431
    ,
    36.26905399834518
]]
const cameraPositionArray: any[] = positionArray.map((v) => new THREE.Vector3(...v))
let textPositionInitial : any
let scroll:any
let pc1StartScroll = 3600
let pc1EndScroll
// 5000 difference must be maintained
let pc2StartScroll
    let pc2EndScroll
    let isScrolling=false
window.addEventListener('scroll', (event) => {
    if(isScrolling===false){
        isScrolling=true
    }
    if(isScrolling===true){
        document.getElementById('scrollingGif')!.style.display= "none"
        document.getElementById('scrollingGif')?.classList.add("fadingDiv")
    }
    if(camera.aspect<1){
        document.getElementById('scrollingGifImgMobile')!.style.display = 'flex';
        document.getElementById('scrollingGifImg')!.style.display = 'none';
        document.getElementById('body')!.style.minHeight = "21200px";
    pc1EndScroll = 9000
// 5000 difference must be maintained
 pc2StartScroll = 13600
    pc2EndScroll = 18000
    }else if(camera.aspect>1){
        document.getElementById('scrollingGifImgMobile')!.style.display = 'none';
        document.getElementById('scrollingGifImg')!.style.display = 'flex';
        document.getElementById('body')!.style.minHeight = "19700px";
      pc1EndScroll = 8000
// 5000 difference must be maintained
pc2StartScroll = 13000
     pc2EndScroll = 17000
    }
    scroll = window.scrollY
    camera.aspect = sizes.width/sizes.height    
    if(scroll<pc1StartScroll || scroll>pc1EndScroll ){
        document.getElementById('pc1div').style.display= "none"
    }
    if(scroll<pc2StartScroll || scroll>pc2EndScroll){
        document.getElementById('pc2div').style.display= "none"

    }
     if (scroll > 0 && scroll < 50) {
        textPositionInitial = companyNameText.position
        scrollCount = 0
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.46,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > 50 && scroll < 100) {
        textPositionInitial = companyNameText.position
        scrollCount = 1
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.46,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > 100 && scroll < 200) {
        gsap.to(camera.position, {
            x: -98.37,
            y: 66.59,
            z: 134.03,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.611,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }
    else if (scroll > 200 && scroll < 300) {
        gsap.to(camera.position, {
            x: -101.74,
            y: 65.38,
            z: 117.46,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.762,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    } else if (scroll > 300 && scroll < 400) {
        gsap.to(camera.position, {
            x: -105.11,
            y: 64.17,
            z: 100.89,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){
            gsap.to(camera.rotation, {
                x: 0,
                y: -0.762,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
        else{
            // for desktop
            gsap.to(camera.rotation, {
                x: 0,
                y: -0.913,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }else if (scroll > 400 && scroll < 500) {
        // for mobile devices
        if(camera.aspect<1){
            gsap.to(camera.position, {
                x: -101.74,
                y: 62.96,
                z: 84.32,
                duration: 1,
                ease: 'Power3.inOut'
            })
            gsap.to(camera.rotation, {
                x: 0,
                y: -0.762,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
        else{
            // for desktop
            gsap.to(camera.position, {
                x: -101.74,
                y: 62.96,
                z: 84.32,
                duration: 1,
                ease: 'Power3.inOut'
            })
            gsap.to(camera.rotation, {
                x: 0,
                y: -1.064,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }else if (scroll > 500 && scroll < 600) {
        scrollCount = 2
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
    })
    if(camera.aspect<1){

        gsap.to(camera.rotation, {
            x: 0,
            y: -0.762,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else{
        gsap.to(camera.rotation, {
            x: 0,
            y: -1.215,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }
   }else if (scroll > 600 && scroll < 700) {
    scrollCount = 2
    gsap.to(camera.position, {
x: -95,
y: 60.54,
z: 51.18,
        duration: 1,
})
if(camera.aspect<1){

    gsap.to(camera.rotation, {
        x: 0,
        y: -0.762,
        z: 0,
        duration: 1,
        ease: 'Power3.inOut'
    })
}else{
    
    gsap.to(camera.rotation, {
        x: 0,
        y: -1.366,
        z: 0,
        duration: 1,
        ease: 'Power3.inOut'
    })
}
}else if (scroll > 700 && scroll < 800) {
    scrollCount = 2
    gsap.to(camera.position, {
        x: -91.63,
        y: 59.33,
        z: 34.61,
        duration: 1,
})
if(camera.aspect<1){

    gsap.to(camera.rotation, {
        x: 0,
        y: -0.762,
        z: 0,
        duration: 1,
        ease: 'Power3.inOut'
    })
}else{
    
    gsap.to(camera.rotation, {
        x: 0,
        y: -1.517,
        z: 0,
        duration: 1,
        ease: 'Power3.inOut'
    })
}
}else if (scroll > 800 && scroll < 900) {
    scrollCount = 2
    gsap.to(camera.position, {
        x: -88.26,
        y: 58.12,
        z: 18.04,
        duration: 1,
})
if(camera.aspect<1){

    gsap.to(camera.rotation, {
        x: 0,
        y: -0.971,
        z: 0,
        duration: 1,
        ease: 'Power3.inOut'
    })
}else{
    
    gsap.to(camera.rotation, {
        x: 0,
        y: -1.668,
        z: 0,
        duration: 1,
        ease: 'Power3.inOut'
    })
}
}else if (scroll > 900 && scroll < 1000) {
    scrollCount = 2
    gsap.to(camera.position, {
        x: -84.89,
        y: 56.91,
        z: 8.47,
        duration: 1,
})
if(camera.aspect<1){
    
    gsap.to(camera.rotation, {
        x: 0,
        y: -0.971,
        z: 0,
        duration: 1,
        ease: 'Power3.inOut'
    })

}else{
    
    gsap.to(camera.rotation, {
        x: 0,
        y: -1.705,
        z: 0,
        duration: 1,
        ease: 'Power3.inOut'
    })
}
} else if (scroll > 1000 && scroll < 1100) {
        scrollCount = 3
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration:1,
            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){

            gsap.to(camera.rotation, {
                x: 0,
                y: - 0.971,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }else{
            
            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.743,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
            
        }
    }  else if (scroll > 1100 && scroll < 1200) {
        scrollCount = 3
        gsap.to(camera.position, {
            x: -78.15,
y: 54.49,
z: 0.13,
            duration:1,
            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){

            gsap.to(camera.rotation, {
                x: 0,
                y: -0.971,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }else{
            
            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.7805,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }

    } else if (scroll > 1200 && scroll < 1300) {
        scrollCount = 3
        gsap.to(camera.position, {
            x: -74.78,
            y:53.28,
            z: -0.54,
            duration:1,
            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){

            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.18,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }else{
            
            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.818,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }
    } else if (scroll > 1300 && scroll < 1400) {
        scrollCount = 3
        gsap.to(camera.position, {
            x: -71.41,
            y: 52.07,
            z: -1.21,
            duration:1,
            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){

            gsap.to(camera.rotation, {
                x: 0,
                y: -1.18,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }else{
            
            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.855,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }

    } else if (scroll > 1400 && scroll < 1500) {
        scrollCount = 3
        gsap.to(camera.position, {
            x: -68.04,
y: 50.86,
z: -1.88,
            duration:1,
            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){
            gsap.to(camera.rotation, {
                x: 0,
                y: -1.18,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })

        }else{
            
            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.893,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }

    }else if (scroll > 1500 && scroll < 1600) {
        scrollCount = 4
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,

            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){

            gsap.to(camera.rotation, {
                x: 0,
                y: -1.18,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }else{
            
            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.895,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }
    } else if (scroll > 1600 && scroll < 1700) {
        scrollCount = 4
        gsap.to(camera.position, {
x: -61.3,
y: 48.5282,
z: -3.22,
            duration: 1,

            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){

            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.389,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }else{
            
            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.896,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }
    }  else if (scroll > 1700 && scroll < 1800) {
        scrollCount = 4
        gsap.to(camera.position, {
            x: -57.93,
            y: 48.5282,
            z: -4.2,
            duration: 1,

            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){

            gsap.to(camera.rotation, {
                x: 0,
                y: -1.389,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }else{
            
            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.897,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }
    } else if (scroll > 1800 && scroll < 1900) {
        scrollCount = 4
        gsap.to(camera.position, {
            x: -50.56,
            y: 48.5282,
            z:-5.5,
            duration: 1,

            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){

            gsap.to(camera.rotation, {
                x: 0,
                y: -1.598,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }else{
            
            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.898,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }
    }  else if (scroll > 1900 && scroll < 2000) {
        gsap.to(camera.position, {
            x  : -42.19,
            y :  48.5282,
            z : -6.8,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(camera.aspect<1){

            gsap.to(camera.rotation, {
                x: 0,
                y: -1.807,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }else{
            
            gsap.to(camera.rotation, {
                x: 0,
                y: - 1.9,
                z: 0,
                duration: 1,
                
                ease: 'Power3.inOut'
            })
        }
    }  else if (scroll > 2000 && scroll < 2100) {
        gsap.to(camera.position, {
            x  : -36.767,
            y :  48.5282,
            z : -10.032,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: - 1.95,
            z: 0,
            duration: 1,

            ease: 'Power3.inOut'
        })
    }  
    else if (scroll > 2100 && scroll < 2200) {
        scrollCount = 5
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,

            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2,
            z: 0,
            duration: 1,

            ease: 'Power3.inOut'
        })
    }   else if (scroll > 2200 && scroll < 2300) {
        scrollCount = 5
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 1,
            y: positionArray[scrollCount][1] - 0.5,
            z: positionArray[scrollCount][2],
            duration: 1,

            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.2,
            z: 0,
            duration: 1,

            ease: 'Power3.inOut'
        })
    } else if (scroll > 2300 && scroll < 2400) {
        scrollCount = 5
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 2,
            y: positionArray[scrollCount][1] - 0.5,
            z: positionArray[scrollCount][2],
            duration: 1,

            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.3,
            z: 0,
            duration: 1,

            ease: 'Power3.inOut'
        })
    }  else if (scroll > 2400 && scroll < 2500) {
        scrollCount = 5
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 3,
            y: positionArray[scrollCount][1] - 0.5,
            z: positionArray[scrollCount][2],
            duration: 1,

            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.4,
            z: 0,
            duration: 1,

            ease: 'Power3.inOut'
        })
    }  else if (scroll > 2500 && scroll < 2600) {
        scrollCount = 6
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration:1,

            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.5,
            z: 0,
            duration:1,

            ease: 'Power3.inOut'
        })
    } else if (scroll > 2600 && scroll < 2700) {
        gsap.to(camera.position, {
          x: -22.6884,
          y: 44,
          z: -6.0874,
            duration:1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.75,
            z: 0,
            duration:1,

            ease: 'Power3.inOut'
        })
    }else if (scroll > 2700 && scroll < 2800) {
        gsap.to(camera.position, {
          x: -22,
          y: 44,
          z: -5.5,
            duration:1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.85,
            z: 0,
            duration:1,

            ease: 'Power3.inOut'
        })
    }else if (scroll > 2800 && scroll < 2900) {
        gsap.to(camera.position, {
          x: -21,
          y: 44,
          z: -5,
            duration:1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.9,
            z: 0,
            duration:1,

            ease: 'Power3.inOut'
        })
    }else if (scroll > 2900 && scroll < 3000) {
        gsap.to(camera.position, {
          x: -20,
          y: 44,
          z: -4,
            duration:1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3,
            z: 0,
            duration:1,

            ease: 'Power3.inOut'
        })
    }  else if (scroll > 3000 && scroll < 3100) {
        scrollCount = 7
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.05,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > 3100 && scroll < 3200) {
        scrollCount = 7
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2] + 0.05,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.05,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > 3200 && scroll < 3300) {
        scrollCount = 7
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2] +0.1,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.05,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > 3300 && scroll < 3400) {
        scrollCount = 7
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2] +0.15,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.05,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > 3400 && scroll < 3500) {
        scrollCount = 7
        gsap.to(companyNameText.position, {
            x: companyNameText.position.x,
            y: -4,
            z: companyNameText.position.z,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2] + 0.2,
            duration: 1,
            onComplete: function () {
                window.inProgress = false
                if (window.showD1 === true) {
                    document.getElementById('pc1div').style.display= "flex"
                    document.getElementById('pc1div').style.transform ="translateY("+(3600)+"px)"
                    document.getElementById('pc1div')?.classList.add("fadingDiv")
                    window.showD1 = false
                    console.log('block',window.showD1)
                }
            },
            onStart: function () {
                console.log(window.inProgress = true)
            },
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.1385,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > 3500 && scroll < 3600) {
        if (scroll > prevScrollY && scroll>3500) {
            scrollCount = 8
            gsap.to(companyNameText.position, {
                x: companyNameText.position.x,
                y: -8,
                z: companyNameText.position.z,
                duration: 1,
                ease: 'Power3.inOut'
            })
            gsap.to(camera.position, {
                x: positionArray[scrollCount][0],
                y: positionArray[scrollCount][1],
                z: positionArray[scrollCount][2],
                ease: 'Power3.inOut',
                duration: 1,
               
    
            })
        } else if (scroll < prevScrollY) {
            gsap.to(companyNameText.position, {
                x: companyNameText.position.x,
                y: -4,
                z: companyNameText.position.z,
                duration: 1,
                ease: 'Power3.inOut'
            })
            scrollCount = 7
            // console.log(scroll)
            document.getElementById('pc1div').style.display = "none"
            document.getElementById('pc1div')?.classList.remove("fadingDiv")

            gsap.to(camera.position, {
                x: positionArray[scrollCount][0],
                y: positionArray[scrollCount][1],
                z: positionArray[scrollCount][2],
                duration: 1,
                ease: 'Power3.inOut',
                onComplete: function () {
                    // console.log('complete')
                    window.inProgress = false
                    if (window.showD1 === true) {
                        document.getElementById('pc1div').style.display= "flex"
                        document.getElementById('pc1div').style.transform ="translateY("+(3600)+"px)"
                        document.getElementById('pc1div')?.classList.add("fadingDiv")
                        window.showD1 = false
                        console.log('block',window.showD1)
                    }
                },
                onStart: function () {
                    console.log(window.inProgress = true)
                },
            })
        }
    } else if (scroll > pc1StartScroll && scroll < pc1EndScroll) {
            if (window.inProgress) { window.showD1 = true }
            else {
                window.showD1 = false
                document.getElementById('pc1div').style.transform ="translateY("+(pc1StartScroll)+"px)"
                document.getElementById('pc1div').style.display = "flex"
                document.getElementById('pc1div')?.classList.add("fadingDiv")
                if(scroll<4500 && document.getElementById('pc1div').style.display === "flex"){
                    pc2.monitorMaterial.map = pc1ScreenTopTexture
                }else if(scroll>4500 && document.getElementById('pc1div').style.display === "flex"){
                    pc2.monitorMaterial.map = pc1ScreenBottomTexture
                }
    }}
    else if (scroll > pc1EndScroll && scroll < (pc1EndScroll+100)) {
        if (scroll > prevScrollY && scroll>(pc1EndScroll + 50)) {
            gsap.to(companyNameText.position, {
                x: companyNameText.position.x,
                y: -8,
                z: companyNameText.position.z,
                duration: 1,
                ease: 'Power3.inOut'
            })
            document.getElementById('pc1div').style.display = "none"
            document.getElementById('pc1div')?.classList.remove("fadingDiv")

            scrollCount = 9
            gsap.to(camera.position, {
                x: positionArray[scrollCount][0],
                y: positionArray[scrollCount][1],
                z: positionArray[scrollCount][2],
                duration: 1,
            ease: 'Power3.inOut'
            })
         
        } else if (scroll < prevScrollY) {
            scrollCount = 8
if(scroll<(pc1EndScroll+50)){
    document.getElementById('pc1div').style.display = "flex"
}
            gsap.to(camera.position, {
                x: positionArray[scrollCount][0],
                y: positionArray[scrollCount][1],
                z: positionArray[scrollCount][2],
                duration: 1,
                ease: 'Power3.inOut'
            })
            gsap.to(camera.rotation, {
                x: 0,
                y: -3.15,
                z: 0,
                
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }else if (scroll > (pc1EndScroll+100) && scroll < (pc1EndScroll+150)) {
        document.getElementById('pc1div').style.display = "none"
        document.getElementById('pc1div')?.classList.remove("fadingDiv")
        scrollCount = 10
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1] ,
            z: positionArray[scrollCount][2] - 1,
            duration: 1,
            ease: 'Power3.inOut'
        })
      
        if(scroll<prevScrollY){
              
            gsap.to(camera.rotation, {
                x: 0,
                y: -3.15,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }else if (scroll > (pc1EndScroll+150) && scroll < (pc1EndScroll+200)) {
        scrollCount = 10
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1]+ 0.5,
            z: positionArray[scrollCount][2] - 2,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: 0,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -3.15,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }else if (scroll > (pc1EndScroll+200) && scroll < (pc1EndScroll+300)) {
        scrollCount = 10
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1] + 0.5,
            z: positionArray[scrollCount][2] - 3,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -3.15,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }else if (scroll > (pc1EndScroll+300) && scroll < (pc1EndScroll+400)) {
        scrollCount = 10
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1] + 0.5,
            z: positionArray[scrollCount][2] - 4,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -3.15,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }else if (scroll > (pc1EndScroll+400) && scroll < (pc1EndScroll+500)) {
        gsap.to(camera.position, {
            x :  -18.3187,
            y:  45.9261,
            z :  -16.873,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.5,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -3,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }else if (scroll > (pc1EndScroll+500) && scroll < (pc1EndScroll+600)) {
        gsap.to(camera.position, {
            x :  -18.3187,
            y:  45.9261,
            z :  -17,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.5,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
       
    }else if (scroll > (pc1EndScroll+600) && scroll < (pc1EndScroll+700)) {
        gsap.to(camera.position, {
            x :  -18.3187,
            y:  45.9261,
            z :  -18,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.5,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
       
    } else if (scroll > (pc1EndScroll+700) && scroll < (pc1EndScroll+800)) {
        gsap.to(camera.position, {
            x :  -18.3187,
            y:  45.9261,
            z :  -19,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.5,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
       
    }else if (scroll > (pc1EndScroll+800) && scroll < (pc1EndScroll+900)) {
        gsap.to(camera.position, {
            x :  -18.3187,
            y:  45.9261,
            z :  -20,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.5,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
       
    }  else if (scroll > (pc1EndScroll+900) && scroll < (pc1EndScroll+1000)) {
        scrollCount = 11
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.75,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    } else if (scroll > (pc1EndScroll+1000) && scroll < (pc1EndScroll+1100)) {
        scrollCount = 12
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.75,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -0.75,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    } else if (scroll > (pc1EndScroll+1100) && scroll < (pc1EndScroll+1200)) {
        scrollCount = 12
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 2,
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2] - 2,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.75,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -0.75,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    } else if (scroll > (pc1EndScroll+1200) && scroll < (pc1EndScroll+1300)) {
        scrollCount = 12
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] +3,
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2] -4,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.75,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -0.75,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    } else if (scroll > (pc1EndScroll+1300) && scroll < (pc1EndScroll+1400)) {
        scrollCount = 12
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 4,
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2] - 6,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.75,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -0.75,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    } else if (scroll > (pc1EndScroll+1400) && scroll < (pc1EndScroll+1500)) {
        scrollCount = 12
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] +5,
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2] - 8,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.75,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -0.75,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    } else if (scroll > (pc1EndScroll+1500) && scroll < (pc1EndScroll+1600)) {
        scrollCount = 12
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 6,
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2] - 10,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.85,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -0.75,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }
    else if (scroll > (pc1EndScroll+1600) && scroll < (pc1EndScroll+1700)) {
        scrollCount = 13
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 2,
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.95,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }  else if (scroll > (pc1EndScroll+1700) && scroll < (pc1EndScroll+1800)) {
        scrollCount = 13
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 4,
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.95,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    } else if (scroll > (pc1EndScroll+1800) && scroll < (pc1EndScroll+1900)) {
        scrollCount = 13
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 6,
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.95,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+1900) && scroll < (pc1EndScroll+2000)) {
        scrollCount = 13
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 7,
            y: positionArray[scrollCount][1] + 0.5,
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.95,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+2000) && scroll < (pc1EndScroll+2100)) {
        scrollCount = 13
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 7.5,
            y: positionArray[scrollCount][1] + 1,
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -1,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+2100) && scroll < (pc1EndScroll+2200)) {
        scrollCount = 13
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 8,
            y: positionArray[scrollCount][1] + 1.5,
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -1.1,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+2200) && scroll < (pc1EndScroll+2300)) {
        scrollCount = 13
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 9,
            y: positionArray[scrollCount][1] + 2,
            z: positionArray[scrollCount][2] - 0.5,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -1.15,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+2300) && scroll < (pc1EndScroll+2400)) {
        scrollCount = 13
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] + 9.5,
            y: positionArray[scrollCount][1] + 2,
            z: positionArray[scrollCount][2] - 1,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -1.25,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    } else if (scroll > (pc1EndScroll+2400) && scroll < (pc1EndScroll+2500)) {
        scrollCount = 14
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -1.35,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    } else if (scroll > (pc1EndScroll+2500) && scroll < (pc1EndScroll+2600)) {
        gsap.to(camera.position, {          
x: 5.5416,
y: 47.4994,
z: -32.7964,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    } else if (scroll > (pc1EndScroll+2600) && scroll < (pc1EndScroll+2700)) {
        gsap.to(camera.position, {          
x: 5.5416,
y: 47.4994,
z: -30.7964,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.35,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    } else if (scroll > (pc1EndScroll+2700) && scroll < (pc1EndScroll+2800)) {
        gsap.to(camera.position, {          
x: 5.5416,
y: 47.4994,
z: -28.7964,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.7,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    } else if (scroll > (pc1EndScroll+2800) && scroll < (pc1EndScroll+2900)) {
        gsap.to(camera.position, {          
x: 5.5416,
y: 47.4994,
z: -26.7964,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.7,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    } else if (scroll > (pc1EndScroll+2900) && scroll < (pc1EndScroll+3000)) {
        gsap.to(camera.position, {          
x: 5.5416,
y: 47.4994,
z: -24.7964,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.7,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+3000) && scroll < (pc1EndScroll+3100)) {
        gsap.to(camera.position, {          
x: 5.5416,
y: 47.4994,
z: -24.7964,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.75,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+3100) && scroll < (pc1EndScroll+3200)) {
        gsap.to(camera.position, {          
x: 5.5416,
y: 47.4994,
z: -24.7964,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.8,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+3200) && scroll < (pc1EndScroll+3300)) {
        gsap.to(camera.position, {          
x: 5.5416,
y: 47.4994,
z: -24.7964,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.85,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+3300) && scroll < (pc1EndScroll+3400)) {
        gsap.to(camera.position, {          
x: 5.5416,
y: 47.4994,
z: -24.7964,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -2.9,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }
      else if (scroll > (pc1EndScroll+3400) && scroll < (pc1EndScroll+3500)) {
        scrollCount = 15
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+3500) && scroll < (pc1EndScroll+3600)) {
        scrollCount = 15
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.05,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+3600) && scroll < (pc1EndScroll+3700)) {
        scrollCount = 15
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.075,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+3700) && scroll < (pc1EndScroll+3800)) {
        scrollCount = 15
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.1,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+3800) && scroll < (pc1EndScroll+3900)) {
        scrollCount = 15
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.15,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+3900) && scroll < (pc1EndScroll+4000)) {
        gsap.to(camera.position, {
            x:  3.5962,
            y  : 46.1047,
            z  :  -22.1742,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.175,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    }else if (scroll > (pc1EndScroll+4000) && scroll < (pc1EndScroll+4300)) {
        scrollCount = 16
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -3.3,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
        if(scroll<prevScrollY){
            gsap.to(camera.rotation, {
                x: 0,
                y: -4,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            }) 
        }
    }else if (scroll > (pc1EndScroll+4300) && scroll < (pc1EndScroll+4800)) {
        scrollCount = 17
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0],
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2],
            duration: 1,
            ease: 'Power3.inOut',
            onComplete: function () {
                // console.log('complete')
                window.inProgress = false
                if (window.showD1 === true) {
                    document.getElementById('pc2div').style.display= "flex"
                    document.getElementById('pc2div').style.transform ="translateY("+(pc2StartScroll)+"px)"
                    document.getElementById('pc2div')?.classList.add("fadingDiv")
                    window.showD1 = false
                    console.log('block',window.showD1)
                }
            },
            onStart: function () {
                console.log(window.inProgress = true)
            },
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -4.6,
            z: 0,
            duration: 1,
            ease: 'Power3.inOut'
        })
    } else if (scroll > (pc1EndScroll+4800) && scroll < pc2StartScroll) {
        if(scroll>prevScrollY){
            scrollCount = 18
            gsap.to(camera.position, {
                x: positionArray[scrollCount][0],
                y: positionArray[scrollCount][1],
                z: positionArray[scrollCount][2],
                duration: 1,
                ease: 'Power3.inOut'
            })
            gsap.to(camera.rotation, {
                x: 0,
                y: -4.71,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
            
        }else if (scroll < prevScrollY) {
            document.getElementById('pc2div').style.display= "none"
            document.getElementById('pc2div')?.classList.remove("fadingDiv")
            scrollCount = 17
            gsap.to(camera.position, {
                x: positionArray[scrollCount][0],
                y: positionArray[scrollCount][1],
                z: positionArray[scrollCount][2],
                duration: 1,
                ease: 'Power3.inOut'
            })
            gsap.to(camera.rotation, {
                x: 0,
                y: -4.71,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })

        }
    }
    else if (scroll > pc2StartScroll && scroll < pc2EndScroll) {
    
            if (window.inProgress) { window.showD1 = true }
            else {
                window.showD1 = false
                document.getElementById('pc2div').style.transform ="translateY("+(pc2StartScroll)+"px)"
                document.getElementById('pc2div')?.classList.add("fadingDiv")
                document.getElementById('pc2div').style.display= "flex"
            }
            if(scroll<(pc2StartScroll+1400) && document.getElementById('pc2div').style.display === "flex"){
                pc2Room2.monitorMaterial.map = pc2ScreenTopTexture
            }else if(scroll>(pc2StartScroll+1400) && document.getElementById('pc2div').style.display === "flex"){
                pc2Room2.monitorMaterial.map = pc2ScreenBottomTexture
            }
    }
    else if (scroll > pc2EndScroll && scroll < (pc2EndScroll+500)) {
        if (scroll > prevScrollY) {
            document.getElementById('pc2div').style.display = "none"
            document.getElementById('pc2div')?.classList.remove("fadingDiv")
            scrollCount = 19
            gsap.to(camera.position, {
                x: positionArray[scrollCount][0],
                y: positionArray[scrollCount][1],
                z: positionArray[scrollCount][2],
                duration: 1,
             
            ease: 'Power3.inOut'
            })
            gsap.to(camera.rotation, {
                x: 0,
                y: -4.65,
                z: 0,
                onComplete:function () {
                    document.getElementById('pc2div').style.display = "none"
                    document.getElementById('pc2div')?.classList.remove("fadingDiv")
                    if(camera.rotation.y>=-3 && scroll<pc2EndScroll){
                        document.getElementById('contactUsForm')!.style.display = 'none'
                        document.getElementById('contactButton')!.style.display = 'none'
                        gsap.to(companyNameText.position, {
                            x: companyNameText.position.x,
                            y: -8,
                            z: companyNameText.position.z,
                            duration: 0.5,
                            ease: 'Power3.inOut'
                        })
                    }
                },
                duration: 1,
                ease: 'Power3.inOut'
            })
        } else if (scroll < prevScrollY) {
            document.getElementById('pc2div').style.display = "none"
            scrollCount = 18
            gsap.to(camera.position, {
                x: positionArray[scrollCount][0],
                y: positionArray[scrollCount][1],
                z: positionArray[scrollCount][2],
                duration: 1,
                ease: 'Power3.inOut'
            })
            gsap.to(camera.rotation, {
                x: 0,
                y: -4.71,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }
    else if (scroll > (pc2EndScroll+500) && scroll < (pc2EndScroll+1000)) {
        document.getElementById('contactButton')!.style.display = 'none'
document.getElementById('contactUsForm')!.style.display = 'none'
gsap.to(companyNameText.position, {
    x: companyNameText.position.x,
    y: -8,
    z: companyNameText.position.z,
    duration: 0.5,
    ease: 'Power3.inOut'
})
document.getElementById('pc2div').style.display= "none"
    
        if (scroll > prevScrollY) {
            document.getElementById('pc2div').style.display = "none"
            scrollCount = 19
            gsap.to(camera.position, {
                x: positionArray[scrollCount][0],
                y: positionArray[scrollCount][1],
                z: positionArray[scrollCount][2],
                duration: 1,
             
            ease: 'Power3.inOut'
            })
            gsap.to(camera.rotation, {
                x: 0,
                y: -3,
                z: 0,
                onComplete:function () {
                    document.getElementById('pc2div').style.display = "none"
                    if(camera.rotation.y>=-3 && scroll<(pc2EndScroll+1000)){
                        document.getElementById('contactUsForm')!.style.display = 'none'
                        document.getElementById('contactButton')!.style.display = 'none'
                        gsap.to(companyNameText.position, {
                            x: companyNameText.position.x,
                            y: -8,
                            z: companyNameText.position.z,
                            duration: 0.5,
                            ease: 'Power3.inOut'
                        })
                    }
                },
                duration: 1,
                ease: 'Power3.inOut'
            })
        } else if (scroll < prevScrollY) {
            document.getElementById('pc2div').style.display = "none"
            scrollCount = 19
            gsap.to(camera.position, {
                x: positionArray[scrollCount][0],
                y: positionArray[scrollCount][1],
                z: positionArray[scrollCount][2],
                duration: 1,
              
                ease: 'Power3.inOut'
            })
            gsap.to(camera.rotation, {
                x: 0,
                y: -4.65,
                z: 0,
                duration: 1,
                ease: 'Power3.inOut'
            })
        }
    }
    else if (scroll > (pc2EndScroll+1000) && scroll < (pc2EndScroll+1500)) {
        if(scroll<prevScrollY && scroll<(pc2EndScroll+1001)){
            gsap.to(companyNameText.position, {
                x: companyNameText.position.x,
                y: -8,
                z: companyNameText.position.z,
                duration: 0.5,
                ease: 'Power3.inOut'
            })
        }else if(scroll>prevScrollY && scroll>(pc2EndScroll+1001)){
            gsap.to(companyNameText.position, {
                x: companyNameText.position.x,
                y: -4,
                z: companyNameText.position.z,
                duration: 0.5,
                ease: 'Power3.inOut'
            })
        }
        scrollCount = 20
    camera.aspect = sizes.width/sizes.height    
    if(camera.aspect<1){
        gsap.to(camera.position, {
            x: positionArray[scrollCount][0] - 32,
            y: positionArray[scrollCount][1],
            z: positionArray[scrollCount][2] + 18,
            duration: 1,
            ease: 'Power3.inOut'
        })
        gsap.to(camera.rotation, {
            x: 0,
            y: -0.75,
            z: 0,
            onStart:function () {
                if(camera.rotation.y>-0.8 && scroll>(pc2EndScroll+1000)){
                    document.getElementById('contactButton')!.style.display = 'flex'
                    document.getElementById('contactButton')?.classList.add("fadingDiv")
                    gsap.to(companyNameText.position, {
                        x: companyNameText.position.x,
                        y: -4,
                        z: companyNameText.position.z,
                        duration: 0.5,
                        ease: 'Power3.inOut'
                    })
                }
            },
            duration: 1,
            ease: 'Power3.inOut'
        })
  }else{
    gsap.to(camera.position, {
        x: positionArray[scrollCount][0],
        y: positionArray[scrollCount][1],
        z: positionArray[scrollCount][2],
        duration: 1,
        ease: 'Power3.inOut'
    })
    gsap.to(camera.rotation, {
        x: 0,
        y: -0.75,
        z: 0,
        onComplete:function () {
            if(camera.rotation.y>-0.8 && scroll>(pc2EndScroll+1000)){
                document.getElementById('contactUsForm')!.style.display = 'flex'
                document.getElementById('contactUsForm')?.classList.add("fadingDiv")
            }
        },
        duration: 1,
        ease: 'Power3.inOut'
    })
  }
   
    }
    renderer.render(scene,camera)

    prevScrollY = scroll
})
let scrollPercent = 0
document.body.onscroll = () => {
    //calculate the current scroll progress as a percentage
    scrollPercent =
        ((document.documentElement.scrollTop || document.body.scrollTop) /
            ((document.documentElement.scrollHeight ||
                document.body.scrollHeight) -
                document.documentElement.clientHeight)) *
        100;
}

const renderInitial = () => {

    // controls.update();
    renderer.render(scene, camera);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))
    // renderer.setPixelRatio(window.devicePixelRatio)
    window.requestAnimationFrame(renderInitial);
  
    if(renderer.shadowMap.autoUpdate===true){
        renderer.shadowMap.autoUpdate = false
    }
}
renderInitial()
const clock = new THREE.Clock()
const startTime = Date.now()
window.addEventListener('load', () => {
    window.scrollTo({
        top: 0
    })
})
window.addEventListener("resize", () => {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    camera.aspect = sizes.width / sizes.height;

    camera.updateProjectionMatrix();

    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))
    // renderer.setPixelRatio(window.devicePixelRatio)

})
